import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { WarehouseCarrierServiceDTO, WmsInstanceDTO } from '@invenco/common-interface/warehousing';
import { CarrierDTO } from '@invenco/common-interface/shipping';
import { Result } from '../../../../shared/helpers/Result';
import { useGateways } from '../../../../gateways/GatewayProvider';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import { ComponentData, Page, Pagination } from '../../../../shared/types';
import { useDataTable } from '../../../../components/data-table';
import { useQueryWithInput, useGatewayMutation } from '../../../../shared/hooks/queries';

type Models = {
  isLoading: boolean;
  hasError: boolean;
  rows?: WarehouseCarrierServiceDTO[];
  pagination?: Pagination;
  total?: number;
  query?: string;
  isCreateWarehouseCarrierServiceModalOpen: boolean;
  wmsInstances?: WmsInstanceDTO[];
  isLoadingWmsInstances: boolean;
  carriers?: CarrierDTO[];
  isLoadingCarriers: boolean;
};

type Operations = {
  openCreateWarehouseCarrierServiceModal: () => void;
  closeCreateWarehouseCarrierServiceModal: () => void;
  search: (query: string) => void;
  navigate: (page: Page) => void;
  refresh: () => void;
  createWarehouseCarrierService: (
    warehouseCarrierService: Partial<WarehouseCarrierServiceDTO>,
  ) => Promise<Result>;
};

export function useWarehouseCarrierServiceListPage(): ComponentData<Models, Operations> {
  const { warehousingGateway, shippingGateway } = useGateways();
  const [tableState, setTableState] = useDataTable();
  const [isCreateWarehouseCarrierServiceModalOpen, setIsCreateWarehouseCarrierServiceModalOpen] =
    useState(false);

  const { data: wmsInstances, isLoading: isLoadingWmsInstances } = useQuery({
    queryKey: ['wmsInstances'],
    queryFn: ({ signal }) => warehousingGateway.getWmsInstances(undefined, { signal }),
  });

  const { data, isLoading, isError, refetch } = useQueryWithInput({
    parentKey: 'warehouseCarrierServices',
    input: {
      take: tableState.page?.take || DEFAULT_PAGE_SIZE,
      cursor: tableState.page?.cursor,
      search: tableState.query,
    },
    query: (input, { signal }) => warehousingGateway.getWarehouseCarrierServices(input, { signal }),
  });

  const { data: carriers, isLoading: isLoadingCarriers } = useQueryWithInput({
    parentKey: 'carriers',
    input: {
      include: { carrierServices: true },
    },
    query: (input, { signal }) => shippingGateway.getCarriers(input, { signal }),
    select: (carrierData) => carrierData.items,
  });

  const { mutate: createWarehouseCarrierService } = useGatewayMutation({
    mutationFn: (warehouseCarrierService: Partial<WarehouseCarrierServiceDTO>) =>
      warehousingGateway.createWarehouseCarrierService(warehouseCarrierService),
    linkedQuery: ['warehouseCarrierServices'],
    linkResponseToQuery: false,
    successMessage: 'Warehouse carrier service added',
  });

  return {
    models: {
      isLoading,
      hasError: isError,
      rows: data?.items,
      pagination: data?.pagination,
      total: data?.total,
      query: tableState.query,
      isCreateWarehouseCarrierServiceModalOpen,
      wmsInstances,
      isLoadingWmsInstances,
      carriers,
      isLoadingCarriers,
    },
    operations: {
      search: (query) => setTableState({ query, page: undefined }),
      navigate: (page) => setTableState({ page }),
      refresh: refetch,
      openCreateWarehouseCarrierServiceModal: () =>
        setIsCreateWarehouseCarrierServiceModalOpen(true),
      closeCreateWarehouseCarrierServiceModal: () =>
        setIsCreateWarehouseCarrierServiceModalOpen(false),
      createWarehouseCarrierService,
    },
  };
}
